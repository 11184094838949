define("discourse/plugins/discourse-events/discourse/templates/admin-plugins-events-source", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <EventsHeader @viewName={{this.viewName}} @message={{this.message}}>
    <DButton
      @id="add-source"
      @action={{action "addSource"}}
      @label="admin.events.source.add.label"
      @title="admin.events.source.add.title"
      @icon="plus"
      @disabled={{this.addDisabled}}
    />
  </EventsHeader>
  
  <div class="admin-events-container">
    {{#if this.hasSources}}
      <table>
        <thead>
          <tr>
            <th>{{I18n "admin.events.source.topics.label"}}</th>
            <th>{{I18n "admin.events.source.label"}}</th>
            <th class="action-column">{{I18n "admin.events.actions"}}</th>
          </tr>
        </thead>
        <tbody>
          {{#each this.sources as |source|}}
            <EventsSourceRow
              @source={{source}}
              @sourceOptionFields={{this.sourceOptionFields}}
              @importPeriods={{this.importPeriods}}
              @providers={{this.providers}}
              @authTypes={{this.authTypes}}
              @removeSource={{action "removeSource"}}
              @setMessage={{action "setMessage"}}
            />
          {{/each}}
        </tbody>
      </table>
    {{else}}
      <p>{{i18n "admin.events.source.none"}}</p>
    {{/if}}
  </div>
  */
  {
    "id": "bbacKLUb",
    "block": "[[[8,[39,0],null,[[\"@viewName\",\"@message\"],[[30,0,[\"viewName\"]],[30,0,[\"message\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@id\",\"@action\",\"@label\",\"@title\",\"@icon\",\"@disabled\"],[\"add-source\",[28,[37,2],[[30,0],\"addSource\"],null],\"admin.events.source.add.label\",\"admin.events.source.add.title\",\"plus\",[30,0,[\"addDisabled\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-events-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasSources\"]],[[[1,\"    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,4],[\"admin.events.source.topics.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,4],[\"admin.events.source.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[14,0,\"action-column\"],[12],[1,[28,[35,4],[\"admin.events.actions\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"sources\"]]],null]],null],null,[[[1,\"          \"],[8,[39,7],null,[[\"@source\",\"@sourceOptionFields\",\"@importPeriods\",\"@providers\",\"@authTypes\",\"@removeSource\",\"@setMessage\"],[[30,1],[30,0,[\"sourceOptionFields\"]],[30,0,[\"importPeriods\"]],[30,0,[\"providers\"]],[30,0,[\"authTypes\"]],[28,[37,2],[[30,0],\"removeSource\"],null],[28,[37,2],[[30,0],\"setMessage\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[12],[1,[28,[35,8],[\"admin.events.source.none\"],null]],[13],[1,\"\\n\"]],[]]],[13]],[\"source\"],false,[\"events-header\",\"d-button\",\"action\",\"if\",\"I18n\",\"each\",\"-track-array\",\"events-source-row\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/admin-plugins-events-source.hbs",
    "isStrictMode": false
  });
});