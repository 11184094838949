define("discourse/plugins/discourse-events/discourse/routes/admin-plugins-events-event", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-events/discourse/models/event", "discourse/plugins/discourse-events/discourse/models/provider"], function (_exports, _object, _service, _discourse, _event, _provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    router: (0, _service.service)(),
    store: (0, _service.service)(),
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      },
      filter: {
        refreshModel: true
      }
    },
    model(params) {
      let page = params.page || 0;
      let order = params.order || "start_time";
      let asc = params.asc || false;
      let filter = params.filter || "connected";
      return _event.default.list({
        page,
        order,
        asc,
        filter
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        page: model.page,
        filter: model.filter,
        order: model.order,
        events: _event.default.toArray(model.events),
        withTopicsCount: model.with_topics_count,
        withoutTopicsCount: model.without_topics_count,
        providers: _provider.default.toArray(this.store, model.providers),
        selectAll: false
      });
    },
    willTransition() {
      this.controllerFor("adminPlugins.events.event").get("selectedEventIds").clear();
    }
  }, [["method", "willTransition", [_object.action]]]));
});