define("discourse/plugins/discourse-events/discourse/templates/admin-plugins-events-provider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <EventsHeader @viewName={{this.viewName}} @message={{this.message}} />
  
  <div class="admin-events-container">
    {{#if this.hasProviders}}
      <table>
        <thead>
          <tr>
            <th>{{I18n "admin.events.provider.label"}}</th>
            <th>{{i18n "admin.events.provider.authentication.label"}}</th>
            <th class="action-column">{{I18n "admin.events.actions"}}</th>
          </tr>
        </thead>
        <tbody>
          {{#each this.providers as |provider|}}
            <EventsProviderRow
              @provider={{provider}}
              @removeProvider={{action "removeProvider"}}
            />
          {{/each}}
        </tbody>
      </table>
    {{else}}
      <p>{{i18n "admin.events.provider.none"}}</p>
    {{/if}}
  </div>
  */
  {
    "id": "eM0x6ezp",
    "block": "[[[8,[39,0],null,[[\"@viewName\",\"@message\"],[[30,0,[\"viewName\"]],[30,0,[\"message\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-events-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasProviders\"]],[[[1,\"    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,2],[\"admin.events.provider.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,3],[\"admin.events.provider.authentication.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[14,0,\"action-column\"],[12],[1,[28,[35,2],[\"admin.events.actions\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"providers\"]]],null]],null],null,[[[1,\"          \"],[8,[39,6],null,[[\"@provider\",\"@removeProvider\"],[[30,1],[28,[37,7],[[30,0],\"removeProvider\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[12],[1,[28,[35,3],[\"admin.events.provider.none\"],null]],[13],[1,\"\\n\"]],[]]],[13]],[\"provider\"],false,[\"events-header\",\"if\",\"I18n\",\"i18n\",\"each\",\"-track-array\",\"events-provider-row\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/admin-plugins-events-provider.hbs",
    "isStrictMode": false
  });
});