define("discourse/plugins/discourse-events/discourse/routes/admin-plugins-events-provider", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-events/discourse/models/provider"], function (_exports, _service, _discourse, _provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    store: (0, _service.service)(),
    model() {
      return _provider.default.all();
    },
    setupController(controller, model) {
      controller.setProperties({
        providers: _provider.default.toArray(this.store, model.providers)
      });
      controller.setMessage("info");
    }
  });
});