define("discourse/plugins/discourse-events/discourse/components/event-rsvp", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember-decorators/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/models/event-rsvp", "discourse/plugins/discourse-events/discourse/components/modal/event-rsvp", "@ember/template-factory"], function (_exports, _component, _object, _service, _component2, _decorators, _I18n, _eventRsvp, _eventRsvp2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser}}
    <DButton
      @action={{this.openModal}}
      @title="event_rsvp.attendees.title"
      @label="event_rsvp.attendees.title"
      @icon="users"
      class="btn-event-attendees"
    />
    <DropdownSelectBox
      @value={{this.topic.event_user.rsvp}}
      @content={{this.rsvpOptions}}
      @onChange={{this.updateRsvp}}
      @options={{hash
        disabled=this.updatingRsvp
        translatedNone=(i18n "event_rsvp.rsvp.none")
      }}
      class="event-rsvp-dropdown"
    />
  {{/if}}
  */
  {
    "id": "EUbTk5fY",
    "block": "[[[41,[30,0,[\"currentUser\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-event-attendees\"]],[[\"@action\",\"@title\",\"@label\",\"@icon\"],[[30,0,[\"openModal\"]],\"event_rsvp.attendees.title\",\"event_rsvp.attendees.title\",\"users\"]],null],[1,\"\\n  \"],[8,[39,2],[[24,0,\"event-rsvp-dropdown\"]],[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"topic\",\"event_user\",\"rsvp\"]],[30,0,[\"rsvpOptions\"]],[30,0,[\"updateRsvp\"]],[28,[37,3],null,[[\"disabled\",\"translatedNone\"],[[30,0,[\"updatingRsvp\"]],[28,[37,4],[\"event_rsvp.rsvp.none\"],null]]]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"dropdown-select-box\",\"hash\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/event-rsvp.hbs",
    "isStrictMode": false
  });
  const EventRsvp = dt7948.c(class EventRsvp extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    updatingRsvp = false;
    updateRsvp(type) {
      this.set("updatingRsvp", true);
      const data = {
        type,
        username: this.currentUser.username,
        topic_id: this.get("topic.id")
      };
      _eventRsvp.default.save(data).then(() => {
        this.setProperties({
          updatingRsvp: false,
          "topic.event_user": {
            rsvp: type
          }
        });
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateRsvp", [_object.action]))();
    rsvpOptions() {
      return _eventRsvp.rsvpTypes.map(rsvpType => {
        return {
          id: rsvpType,
          name: _I18n.default.t(`event_rsvp.${rsvpType}.user_label`)
        };
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "rsvpOptions", [_decorators.default]))();
    openModal() {
      event?.preventDefault();
      this.modal.show(_eventRsvp2.default, {
        model: {
          topic: this.get("topic")
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "openModal", [_object.action]))();
  }, [(0, _component2.classNames)("event-rsvp")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventRsvp);
});