define("discourse/plugins/discourse-events/discourse/components/events-event-row", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="directory-table__cell start-time">
    {{raw-date this.event.start_time}}
  </div>
  
  <div class="directory-table__cell name">
    {{#if this.showTopics}}
      {{#each this.event.topic_ids as |topic_id|}}
        <a
          {{on "click" (fn this.openTopic topic_id)}}
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >{{this.event.name}}</a>
      {{/each}}
    {{else}}
      {{this.event.name}}
    {{/if}}
  </div>
  
  <div class="directory-table__cell tags">
    <div class="event-tags">
      {{#if this.providerLabel}}
        <EventTag
          @label={{this.providerLabel}}
          @href="/admin/plugins/events/provider"
          @class="provider"
        />
      {{/if}}
      {{#if this.event.series_id}}
        <EventTag
          @label={{i18n "admin.events.event.series.label"}}
          @class="series"
        />
      {{/if}}
    </div>
  </div>
  
  <div class="directory-table__cell select-event">
    <Input @type="checkbox" @checked={{this.event.selected}} />
  </div>
  */
  {
    "id": "6cCk89o6",
    "block": "[[[10,0],[14,0,\"directory-table__cell start-time\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"event\",\"start_time\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"directory-table__cell name\"],[12],[1,\"\\n\"],[41,[30,0,[\"showTopics\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"event\",\"topic_ids\"]]],null]],null],null,[[[1,\"      \"],[11,3],[24,\"target\",\"_blank\"],[24,\"rel\",\"noopener noreferrer\"],[24,\"role\",\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"openTopic\"]],[30,1]],null]],null],[12],[1,[30,0,[\"event\",\"name\"]]],[13],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"    \"],[1,[30,0,[\"event\",\"name\"]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"directory-table__cell tags\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"event-tags\"],[12],[1,\"\\n\"],[41,[30,0,[\"providerLabel\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@label\",\"@href\",\"@class\"],[[30,0,[\"providerLabel\"]],\"/admin/plugins/events/provider\",\"provider\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"event\",\"series_id\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@label\",\"@class\"],[[28,[37,7],[\"admin.events.event.series.label\"],null],\"series\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"directory-table__cell select-event\"],[12],[1,\"\\n  \"],[8,[39,8],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"event\",\"selected\"]]]],null],[1,\"\\n\"],[13]],[\"topic_id\"],false,[\"raw-date\",\"if\",\"each\",\"-track-array\",\"on\",\"fn\",\"event-tag\",\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-event-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    tagName: "div",
    classNameBindings: [":directory-table__row", ":events-event-row", "event.selected:selected"],
    selectEvent() {
      this.modifySelection([this.event.id], this.event.selected);
    },
    provider(providerId, providers) {
      return providers.find(provider => provider.id === providerId);
    },
    click() {
      this.set("event.selected", !this.get("event.selected"));
    },
    openTopic(topicId) {
      event?.preventDefault();
      event?.stopPropagation();
      window.open(`/t/${topicId}`, "_blank");
    },
    providerLabel(providerType) {
      if (providerType) {
        return _I18n.default.t(`admin.events.provider.provider_type.${providerType}.label`);
      } else {
        return null;
      }
    }
  }, [["method", "selectEvent", [(0, _decorators.observes)("event.selected")]], ["method", "provider", [(0, _decorators.default)("event.provider_id", "providers")]], ["method", "openTopic", [_object.action]], ["method", "providerLabel", [(0, _decorators.default)("provider.provider_type")]]])));
});