define("discourse/plugins/discourse-events/discourse/components/modal/add-event", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "I18n", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="add-event-modal"
    @title={{this.title}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <EventForm @event={{@model.event}} @updateEvent={{action "updateEvent"}} />
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{action "saveEvent"}}
        @label="add_event.event_add"
      />
      <a href class="clear" {{on "click" this.clear}}>{{i18n
          "add_event.event_clear"
        }}</a>
    </:footer>
  </DModal>
  */
  {
    "id": "ItQn1OSw",
    "block": "[[[8,[39,0],[[24,0,\"add-event-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[30,0,[\"title\"]],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@event\",\"@updateEvent\"],[[30,2,[\"event\"]],[28,[37,2],[[30,0],\"updateEvent\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,2],[[30,0],\"saveEvent\"],null],\"add_event.event_add\"]],null],[1,\"\\n    \"],[11,3],[24,6,\"\"],[24,0,\"clear\"],[4,[38,4],[\"click\",[30,0,[\"clear\"]]],null],[12],[1,[28,[35,5],[\"add_event.event_clear\"],null]],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"event-form\",\"action\",\"d-button\",\"on\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs",
    "isStrictMode": false
  });
  class AddEvent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "bufferedEvent", [_tracking.tracked], function () {
      return this.model.event;
    }))();
    #bufferedEvent = (() => (dt7948.i(this, "bufferedEvent"), void 0))();
    title = (() => _I18n.default.t("add_event.modal_title"))();
    valid = true;
    clear() {
      event?.preventDefault();
      this.bufferedEvent = null;
    }
    static #_2 = (() => dt7948.n(this.prototype, "clear", [_object.action]))();
    saveEvent() {
      if (this.valid) {
        this.get("model.update")(this.bufferedEvent);
        this.closeModal();
      } else {
        this.flash = _I18n.default.t("add_event.error");
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "saveEvent", [_object.action]))();
    updateEvent(event, valid) {
      this.bufferedEvent = event;
      this.valid = valid;
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateEvent", [_object.action]))();
  }
  _exports.default = AddEvent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddEvent);
});