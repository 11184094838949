define("discourse/plugins/discourse-events/discourse/controllers/admin-plugins-events-log", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/plugins/discourse-events/discourse/mixins/message", "discourse/plugins/discourse-events/discourse/models/log"], function (_exports, _controller, _object, _computed, _message, _log) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsEventsLog extends _controller.default.extend(_message.default) {
    static #_ = (() => dt7948.g(this.prototype, "hasLogs", [(0, _computed.notEmpty)("logs")]))();
    #hasLogs = (() => (dt7948.i(this, "hasLogs"), void 0))();
    queryParams = ["order", "asc"];
    order = "";
    asc = null;
    viewName = "log";
    loading = false;
    loadingComplete = false;
    page = 0;
    loadMore() {
      if (this.loading || this.loadingComplete) {
        return;
      }
      let page = this.page + 1;
      this.page = page;
      this.loading = true;
      let params = {
        page
      };
      if (this.asc) {
        params.asc = this.asc;
      }
      if (this.order) {
        params.order = this.order;
      }
      _log.default.list(params).then(result => {
        if (result?.logs && result.logs.length) {
          this.logs.pushObjects(result.logs.map(p => _log.default.create(p)));
        } else {
          this.loadingComplete = true;
        }
      }).finally(() => {
        this.loading = false;
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    updateOrder(field, asc) {
      this.setProperties({
        order: field,
        asc
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateOrder", [_object.action]))();
  }
  _exports.default = AdminPluginsEventsLog;
});