define("discourse/plugins/discourse-events/discourse/components/modal/event-rsvp", ["exports", "@ember/component", "@ember/application", "@ember/object", "discourse/models/user", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/models/event-rsvp", "@ember/template-factory"], function (_exports, _component, _application, _object, _user, _decorators, _I18n, _eventRsvp, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="event-rsvp-modal"
    @title={{this.title}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <div class="header">
        <ul class="types nav nav-pills">
          {{#each this.rsvpTypes as |rsvpType|}}
            <li>
              <a
                href
                {{on "click" (fn this.setType rsvpType)}}
                class={{if (eq this.type rsvpType) "active"}}
              >
                {{i18n (concat "event_rsvp." rsvpType ".label")}}
              </a>
            </li>
          {{/each}}
        </ul>
      </div>
      <div class="list">
        {{#if this.loadingList}}
          {{loading-spinner size="small"}}
        {{else}}
          <ul>
            {{#each this.filteredList as |user|}}
              <li>
                <UserInfo @user={{user}}>
                  {{#if this.currentUser.staff}}
                    <DButton
                      class="btn compose-pm"
                      @action={{fn this.composePrivateMessage user}}
                      @icon="envelope"
                      @label="user.private_message"
                    />
                  {{/if}}
                </UserInfo>
              </li>
            {{/each}}
          </ul>
        {{/if}}
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "ZS0mUSmU",
    "block": "[[[8,[39,0],[[24,0,\"event-rsvp-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[30,0,[\"title\"]],[30,1],[30,0,[\"flash\"]]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"types nav nav-pills\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"rsvpTypes\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n            \"],[11,3],[24,6,\"\"],[16,0,[52,[28,[37,4],[[30,0,[\"type\"]],[30,2]],null],\"active\"]],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"setType\"]],[30,2]],null]],null],[12],[1,\"\\n              \"],[1,[28,[35,7],[[28,[37,8],[\"event_rsvp.\",[30,2],\".label\"],null]],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"list\"],[12],[1,\"\\n\"],[41,[30,0,[\"loadingList\"]],[[[1,\"        \"],[1,[28,[35,9],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"filteredList\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n              \"],[8,[39,10],null,[[\"@user\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"                  \"],[8,[39,11],[[24,0,\"btn compose-pm\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,6],[[30,0,[\"composePrivateMessage\"]],[30,3]],null],\"envelope\",\"user.private_message\"]],null],[1,\"\\n\"]],[]],null],[1,\"              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"rsvpType\",\"user\"],false,[\"d-modal\",\"each\",\"-track-array\",\"if\",\"eq\",\"on\",\"fn\",\"i18n\",\"concat\",\"loading-spinner\",\"user-info\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/event-rsvp.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    userList: [],
    type: "going",
    title: _I18n.default.t("event_rsvp.attendees.title"),
    rsvpTypes: _eventRsvp.rsvpTypes,
    didReceiveAttrs() {
      this._super();
      this.setUserList();
    },
    setUserList() {
      this.set("loadingList", true);
      const type = this.get("type");
      const topic = this.get("model.topic");
      const data = {
        type,
        topic_id: topic.id
      };
      _eventRsvp.default.list(data).then(response => {
        let userList = response.users || [];
        this.setProperties({
          userList,
          loadingList: false
        });
      });
    },
    navClass(type) {
      return type === this.get("type") ? "active" : "";
    },
    filteredList(userList) {
      const currentUser = this.get("currentUser");
      if (currentUser) {
        userList.sort(a => {
          if (a.username === currentUser.username) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      return userList;
    },
    setType(type) {
      event?.preventDefault();
      this.set("type", type);
      this.setUserList();
    },
    composePrivateMessage(user) {
      const controller = (0, _application.getOwner)(this).lookup("controller:application");
      this.closeModal();
      controller.send("composePrivateMessage", _user.default.create(user));
    }
  }, [["method", "setUserList", [_object.action]], ["method", "navClass", [_object.action]], ["method", "filteredList", [(0, _decorators.default)("userList")]], ["method", "setType", [_object.action]], ["method", "composePrivateMessage", [_object.action]]])));
});