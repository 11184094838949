define("discourse/plugins/discourse-events/discourse/models/event-rsvp", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsvpTypes = _exports.default = void 0;
  const EventRsvp = _object.default.extend();
  const basePath = "/discourse-events/rsvp";
  const rsvpTypes = _exports.rsvpTypes = ["going", "not_going", "maybe_going", "invited"];
  EventRsvp.reopenClass({
    save(data) {
      return (0, _ajax.ajax)(`${basePath}/update`, {
        type: "PUT",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    list(data) {
      return (0, _ajax.ajax)(`${basePath}/users`, {
        data
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = EventRsvp;
});