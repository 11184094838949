define("discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-interface/calendar-preferences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group calendar">
    <label class="control-label">{{i18n "user.events_calendar.label"}}</label>
    <div class="controls">
      <label>{{i18n "user.events_calendar.first_day_week"}}</label>
      <ComboBox
        @content={{this.weekdaysAvailable}}
        @value={{this.controller.model.custom_fields.calendar_first_day_week}}
      />
    </div>
  </div>
  */
  {
    "id": "oyDioUmW",
    "block": "[[[10,0],[14,0,\"control-group calendar\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user.events_calendar.label\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,0],[\"user.events_calendar.first_day_week\"],null]],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@content\",\"@value\"],[[30,0,[\"weekdaysAvailable\"]],[30,0,[\"controller\",\"model\",\"custom_fields\",\"calendar_first_day_week\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"combo-box\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-interface/calendar-preferences.hbs",
    "isStrictMode": false
  });
});