define("discourse/plugins/discourse-events/discourse/components/events-log-row", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="directory-table__cell">
    {{bound-date @log.created_at}}
  </div>
  
  <div class="directory-table__cell log-level">
    {{@log.level}}
  </div>
  
  <div class="directory-table__cell log-context">
    {{@log.context}}
  </div>
  
  <div class="directory-table__cell log-message">
    {{@log.message}}
  </div>
  */
  {
    "id": "S4lyrvvQ",
    "block": "[[[10,0],[14,0,\"directory-table__cell\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,1,[\"created_at\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"directory-table__cell log-level\"],[12],[1,\"\\n  \"],[1,[30,1,[\"level\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"directory-table__cell log-context\"],[12],[1,\"\\n  \"],[1,[30,1,[\"context\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"directory-table__cell log-message\"],[12],[1,\"\\n  \"],[1,[30,1,[\"message\"]]],[1,\"\\n\"],[13]],[\"@log\"],false,[\"bound-date\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-log-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: "div",
    classNames: ["directory-table__row"]
  }));
});