define("discourse/plugins/discourse-events/discourse/models/source", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/site", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _ajaxError, _site, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Source = _object.default.extend(dt7948.p({
    canImport(importType) {
      return importType === "import" || importType === "import_publish";
    },
    category(categoryId) {
      return _site.default.current().categoriesList.find(c => c.id === categoryId);
    }
  }, [["method", "canImport", [(0, _decorators.default)("import_type")]], ["method", "category", [(0, _decorators.default)("category_id")]]]));
  Source.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/plugins/events/source").catch(_ajaxError.popupAjaxError);
    },
    update(source) {
      return (0, _ajax.ajax)(`/admin/plugins/events/source/${source.id}`, {
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify({
          source
        })
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(source) {
      return (0, _ajax.ajax)(`/admin/plugins/events/source/${source.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    },
    importEvents(source) {
      return (0, _ajax.ajax)(`/admin/plugins/events/source/${source.id}/import`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    syncTopics(source) {
      return (0, _ajax.ajax)(`/admin/plugins/events/source/${source.id}/topics`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Source;
});