define("discourse/plugins/discourse-events/discourse/templates/discovery/calendar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Discovery::Layout
    @model={{this.model}}
    @createTopicDisabled={{this.createTopicDisabled}}
  >
    <:navigation>
      <Discovery::Navigation
        @category={{this.model.category}}
        @tag={{this.model.tag}}
        @additionalTags={{this.model.additionalTags}}
        @filterType={{this.model.filterType}}
        @noSubcategories={{this.model.noSubcategories}}
        @canBulkSelect={{this.canBulkSelect}}
        @bulkSelectHelper={{this.bulkSelectHelper}}
        @createTopic={{this.createTopic}}
        @createTopicDisabled={{this.createTopicDisabled}}
        @canCreateTopicOnTag={{this.model.canCreateTopicOnTag}}
        @toggleTagInfo={{this.toggleTagInfo}}
        @tagNotification={{this.model.tagNotification}}
        @model={{this.model.list}}
        @showDismissRead={{this.showDismissRead}}
        @showResetNew={{this.showResetNew}}
        @dismissRead={{this.dismissRead}}
        @resetNew={{this.resetNew}}
      />
    </:navigation>
  
    <:header>
      {{#if this.model.subcategoryList}}
        <Discovery::CategoriesDisplay
          @categories={{this.model.subcategoryList.categories}}
          @parentCategory={{this.model.subcategoryList.parentCategory}}
        />
      {{/if}}
      {{#if (and this.showTagInfo this.model.tag)}}
        <TagInfo @tag={{this.model.tag}} @list={{this.model.list}} />
      {{/if}}
    </:header>
    <:list>
      <EventsCalendar
        @topics={{this.model.list.topics}}
        @category={{this.model.category}}
        @loading={{this.discovery.loading}}
        @initialDateRange={{this.initialDateRange}}
      />
    </:list>
  </Discovery::Layout>
  */
  {
    "id": "YAMeDOqJ",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@createTopicDisabled\"],[[30,0,[\"model\"]],[30,0,[\"createTopicDisabled\"]]]],[[\"navigation\",\"header\",\"list\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@category\",\"@tag\",\"@additionalTags\",\"@filterType\",\"@noSubcategories\",\"@canBulkSelect\",\"@bulkSelectHelper\",\"@createTopic\",\"@createTopicDisabled\",\"@canCreateTopicOnTag\",\"@toggleTagInfo\",\"@tagNotification\",\"@model\",\"@showDismissRead\",\"@showResetNew\",\"@dismissRead\",\"@resetNew\"],[[30,0,[\"model\",\"category\"]],[30,0,[\"model\",\"tag\"]],[30,0,[\"model\",\"additionalTags\"]],[30,0,[\"model\",\"filterType\"]],[30,0,[\"model\",\"noSubcategories\"]],[30,0,[\"canBulkSelect\"]],[30,0,[\"bulkSelectHelper\"]],[30,0,[\"createTopic\"]],[30,0,[\"createTopicDisabled\"]],[30,0,[\"model\",\"canCreateTopicOnTag\"]],[30,0,[\"toggleTagInfo\"]],[30,0,[\"model\",\"tagNotification\"]],[30,0,[\"model\",\"list\"]],[30,0,[\"showDismissRead\"]],[30,0,[\"showResetNew\"]],[30,0,[\"dismissRead\"]],[30,0,[\"resetNew\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"model\",\"subcategoryList\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@categories\",\"@parentCategory\"],[[30,0,[\"model\",\"subcategoryList\",\"categories\"]],[30,0,[\"model\",\"subcategoryList\",\"parentCategory\"]]]],null],[1,\"\\n\"]],[]],null],[41,[28,[37,4],[[30,0,[\"showTagInfo\"]],[30,0,[\"model\",\"tag\"]]],null],[[[1,\"      \"],[8,[39,5],null,[[\"@tag\",\"@list\"],[[30,0,[\"model\",\"tag\"]],[30,0,[\"model\",\"list\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],null,[[\"@topics\",\"@category\",\"@loading\",\"@initialDateRange\"],[[30,0,[\"model\",\"list\",\"topics\"]],[30,0,[\"model\",\"category\"]],[30,0,[\"discovery\",\"loading\"]],[30,0,[\"initialDateRange\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"discovery/layout\",\"discovery/navigation\",\"if\",\"discovery/categories-display\",\"and\",\"tag-info\",\"events-calendar\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/discovery/calendar.hbs",
    "isStrictMode": false
  });
});