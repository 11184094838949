define("discourse/plugins/discourse-events/discourse/models/event", ["exports", "@ember/array", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _array, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Event = _object.default.extend({
    selected: false
  });
  Event.reopenClass({
    list() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/plugins/events/event", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    listAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/plugins/events/event/all", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(data) {
      return (0, _ajax.ajax)("/admin/plugins/events/event", {
        type: "DELETE",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    connectTopic(data) {
      return (0, _ajax.ajax)("/admin/plugins/events/event/topic/connect", {
        type: "POST",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    updateTopic(data) {
      return (0, _ajax.ajax)("/admin/plugins/events/event/topic/update", {
        type: "POST",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    toArray(events) {
      let selectedEventIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return (0, _array.A)(events.map(event => {
        if (selectedEventIds.includes(event.id)) {
          event.selected = true;
        }
        return Event.create(event);
      }));
    }
  });
  var _default = _exports.default = Event;
});