define("discourse/plugins/discourse-events/discourse/controllers/admin-plugins-events-event", ["exports", "@ember/array", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/components/modal/events-confirm-event-deletion", "discourse/plugins/discourse-events/discourse/components/modal/events-connect-topic", "discourse/plugins/discourse-events/discourse/mixins/message", "discourse/plugins/discourse-events/discourse/models/event"], function (_exports, _array, _controller, _object, _computed, _service, _decorators, _I18n, _eventsConfirmEventDeletion, _eventsConnectTopic, _message, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsEventsEvent extends _controller.default.extend(_message.default) {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "subscription", [(0, _service.service)("events-subscription")]))();
    #subscription = (() => (dt7948.i(this, "subscription"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "hasEvents", [(0, _computed.notEmpty)("events")]))();
    #hasEvents = (() => (dt7948.i(this, "hasEvents"), void 0))();
    selectedEventIds = (() => (0, _array.A)())();
    selectAll = false;
    order = "";
    asc = null;
    filter = null;
    queryParams = ["filter", "order", "asc"];
    static #_5 = (() => dt7948.g(this.prototype, "addDisabled", [(0, _computed.not)("subscription.subscribed")]))();
    #addDisabled = (() => (dt7948.i(this, "addDisabled"), void 0))();
    loadingComplete = false;
    loading = false;
    deleteDisabled(selectedEventIds, hasEvents) {
      return !hasEvents || !selectedEventIds.length;
    }
    static #_6 = (() => dt7948.n(this.prototype, "deleteDisabled", [(0, _decorators.default)("selectedEventIds.[]", "hasEvents")]))();
    selectDisabled(hasEvents) {
      return !hasEvents;
    }
    static #_7 = (() => dt7948.n(this.prototype, "selectDisabled", [(0, _decorators.default)("hasEvents")]))();
    noneLabel(filter) {
      return _I18n.default.t(`admin.events.event.none.${filter === "connected" ? "connected" : "unconnected"}`);
    }
    static #_8 = (() => dt7948.n(this.prototype, "noneLabel", [(0, _decorators.default)("filter")]))();
    unconnectedRoute(filter) {
      return filter === "unconnected";
    }
    static #_9 = (() => dt7948.n(this.prototype, "unconnectedRoute", [(0, _decorators.default)("filter")]))();
    connectedRoute(filter) {
      return filter === "connected";
    }
    static #_10 = (() => dt7948.n(this.prototype, "connectedRoute", [(0, _decorators.default)("filter")]))();
    viewName(filter) {
      return `event.${filter}`;
    }
    static #_11 = (() => dt7948.n(this.prototype, "viewName", [(0, _decorators.default)("filter")]))();
    connectTopicDisabled(selectedEventIds) {
      return selectedEventIds.length !== 1;
    }
    static #_12 = (() => dt7948.n(this.prototype, "connectTopicDisabled", [(0, _decorators.default)("selectedEventIds.[]")]))();
    updateTopicDisabled(selectedEventIds) {
      return selectedEventIds.length !== 1;
    }
    static #_13 = (() => dt7948.n(this.prototype, "updateTopicDisabled", [(0, _decorators.default)("selectedEventIds.[]")]))();
    updateCurrentRouteCount() {
      const events = this.get("events");
      this.set(`${this.unconnectedRoute ? "without" : "with"}TopicsCount`, events.length);
    }
    showTopics(filter) {
      return filter === "connected";
    }
    static #_14 = (() => dt7948.n(this.prototype, "showTopics", [(0, _decorators.default)("filter")]))();
    selectAllEvents() {
      _event.default.listAll({
        filter: this.filter
      }).then(result => {
        this.modifySelection(result.event_ids, true);
      });
    }
    toggleSelectAll() {
      this.toggleProperty("selectAll");
      if (this.selectAll) {
        this.selectAllEvents();
      } else {
        this.modifySelection(this.selectedEventIds, false);
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "toggleSelectAll", [_object.action]))();
    loadMore() {
      if (this.loading || this.loadingComplete) {
        return;
      }
      const page = this.page + 1;
      let params = {
        page
      };
      if (this.filter) {
        params.filter = this.filter;
      }
      if (this.asc) {
        params.asc = this.asc;
      }
      if (this.order) {
        params.order = this.order;
      }
      this.set("loading", true);
      _event.default.list(params).then(result => {
        if (result.events && result.events.length) {
          this.set("page", page);
          this.get("events").pushObjects(_event.default.toArray(result.events, this.selectedEventIds));
        } else {
          this.set("loadingComplete", true);
        }
      }).finally(() => this.set("loading", false));
    }
    static #_16 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    openConnectTopic() {
      const selectedEventId = this.selectedEventIds[0];
      const event = this.get("events").findBy("id", selectedEventId);
      if (!event) {
        return;
      }
      this.modal.show(_eventsConnectTopic.default, {
        model: {
          event,
          onConnectTopic: () => {
            this.set("selectedEventIds", (0, _array.A)());
            this.get("events").removeObject(event);
          }
        }
      });
    }
    static #_17 = (() => dt7948.n(this.prototype, "openConnectTopic", [_object.action]))();
    updateTopic() {
      const selectedEventId = this.selectedEventIds[0];
      const event = this.get("events").findBy("id", selectedEventId);
      if (!event) {
        return;
      }
      this.set("updating", true);
      _event.default.updateTopic({
        event_id: event.id
      }).then(result => {
        if (result.success) {
          this.set("selectedEventIds", (0, _array.A)());
        }
      }).finally(() => this.set("updating", false));
    }
    static #_18 = (() => dt7948.n(this.prototype, "updateTopic", [_object.action]))();
    modifySelection(eventIds, selected) {
      this.get("events").forEach(event => {
        if (eventIds.includes(event.id)) {
          event.set("selected", selected);
        }
      });
      if (selected) {
        this.get("selectedEventIds").addObjects(eventIds);
      } else {
        this.get("selectedEventIds").removeObjects(eventIds);
      }
    }
    static #_19 = (() => dt7948.n(this.prototype, "modifySelection", [_object.action]))();
    openDelete() {
      var _this = this;
      this.modal.show(_eventsConfirmEventDeletion.default, {
        model: {
          eventIds: this.selectedEventIds,
          onDestroyEvents: function () {
            let destroyedEventIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
            let destroyedTopicsEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            _this.set("selectedEventIds", (0, _array.A)());
            const events = _this.get("events");
            if (destroyedEventIds) {
              const destroyedEvents = events.filter(e => destroyedEventIds.includes(e.id));
              events.removeObjects(destroyedEvents);
              _this.updateCurrentRouteCount();
            }
            if (destroyedTopicsEvents) {
              const destroyedTopicsEventIds = destroyedTopicsEvents.map(e => e.id);
              events.forEach(event => {
                if (destroyedTopicsEventIds.includes(event.id)) {
                  event.set("topics", null);
                }
              });
            }
          }
        }
      });
    }
    static #_20 = (() => dt7948.n(this.prototype, "openDelete", [_object.action]))();
    updateOrder(field, asc) {
      this.setProperties({
        order: field,
        asc
      });
    }
    static #_21 = (() => dt7948.n(this.prototype, "updateOrder", [_object.action]))();
  }
  _exports.default = AdminPluginsEventsEvent;
});