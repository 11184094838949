define("discourse/plugins/discourse-events/discourse/components/event-filter-row", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>
    <ComboBox
      @content={{this.queryColumns}}
      @value={{this.filter.query_column}}
      @onChange={{action (mut this.filter.query_column)}}
      class="filter-column"
    />
  </td>
  <td>
    <ComboBox
      @content={{this.queryOperators}}
      @value={{this.filter.query_operator}}
      @onChange={{action (mut this.filter.query_operator)}}
      class="filter-operator"
    />
  </td>
  <td>
    {{#if this.dateQueryColumn}}
      <DateInput
        @date={{this.filter.query_value}}
        @onChange={{action "onChangeDateQueryValue"}}
      />
    {{else}}
      <Input @value={{this.filter.query_value}} class="filter-value" />
    {{/if}}
  </td>
  <td>
    <DButton
      @action={{action "removeFilter"}}
      @icon="times"
      @title="admin.events.filter.remove.title"
    />
  </td>
  */
  {
    "id": "aWYas6/U",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"filter-column\"]],[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"queryColumns\"]],[30,0,[\"filter\",\"query_column\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"filter\",\"query_column\"]]],null]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"filter-operator\"]],[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"queryOperators\"]],[30,0,[\"filter\",\"query_operator\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"filter\",\"query_operator\"]]],null]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"dateQueryColumn\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@date\",\"@onChange\"],[[30,0,[\"filter\",\"query_value\"]],[28,[37,1],[[30,0],\"onChangeDateQueryValue\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],[[24,0,\"filter-value\"]],[[\"@value\"],[[30,0,[\"filter\",\"query_value\"]]]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[8,[39,6],null,[[\"@action\",\"@icon\",\"@title\"],[[28,[37,1],[[30,0],\"removeFilter\"],null],\"times\",\"admin.events.filter.remove.title\"]],null],[1,\"\\n\"],[13]],[],false,[\"combo-box\",\"action\",\"mut\",\"if\",\"date-input\",\"input\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/event-filter-row.hbs",
    "isStrictMode": false
  });
  const QUERY_COLUMNS = [{
    name: "Event Name",
    id: "name"
  }, {
    name: "Start Time",
    id: "start_time"
  }];
  const QUERY_OPERATORS = [{
    name: "Like",
    id: "like"
  }, {
    name: "Greater Than",
    id: "greater_than"
  }, {
    name: "Less Than",
    id: "less_than"
  }];
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    tagName: "tr",
    classNames: ["filter"],
    queryColumns() {
      return QUERY_COLUMNS;
    },
    queryOperators(queryColumn) {
      const operators = QUERY_OPERATORS;
      if (queryColumn === "name") {
        return operators.filter(o => o.id === "like");
      } else {
        return operators;
      }
    },
    dateQueryColumn(queryColumn) {
      return queryColumn === "start_time";
    },
    actions: {
      onChangeDateQueryValue(date) {
        this.filter.set("query_value", date);
      },
      removeFilter() {
        this.removeFilter(this.filter);
      }
    }
  }, [["method", "queryColumns", [_decorators.default]], ["method", "queryOperators", [(0, _decorators.default)("filter.query_column")]], ["method", "dateQueryColumn", [(0, _decorators.default)("filter.query_column")]]])));
});