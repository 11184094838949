define("discourse/plugins/discourse-events/discourse/components/events-header", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-events-controls">
    <h2>{{this.title}}</h2>
  
    <div class="buttons">
      {{yield}}
    </div>
  </div>
  
  <EventsMessage @viewName={{this.viewName}} @message={{this.message}} />
  */
  {
    "id": "aH07+NVP",
    "block": "[[[10,0],[14,0,\"admin-events-controls\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@viewName\",\"@message\"],[[30,0,[\"viewName\"]],[30,0,[\"message\"]]]],null]],[\"&default\"],false,[\"yield\",\"events-message\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNames: ["events-header"],
    title(viewName) {
      return _I18n.default.t(`admin.events.${viewName}.title`);
    }
  }, [["method", "title", [(0, _decorators.default)("viewName")]]])));
});