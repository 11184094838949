define("discourse/plugins/discourse-events/discourse/components/events-subscription-status", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _computed, _service, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.subscription.authorized}}
    {{conditional-loading-spinner size="small" condition=this.unauthorizing}}
    <DButton
      class="deauthorize"
      @title="admin.events.subscription.deauthorize.title"
      @disabled={{this.unauthorizing}}
      @action={{this.deauthorize}}
    >
      {{i18n "admin.events.subscription.deauthorize.label"}}
    </DButton>
    <EventsSubscriptionBadge />
  {{else}}
    <DButton
      @icon="id-card"
      class="btn-primary"
      @label="admin.events.subscription.authorize.label"
      @title="admin.events.subscription.authorize.title"
      @action={{this.authorize}}
      @disabled={{this.authorizeDisabled}}
    />
  {{/if}}
  */
  {
    "id": "SFtDC794",
    "block": "[[[41,[30,0,[\"subscription\",\"authorized\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"size\",\"condition\"],[\"small\",[30,0,[\"unauthorizing\"]]]]]],[1,\"\\n  \"],[8,[39,2],[[24,0,\"deauthorize\"]],[[\"@title\",\"@disabled\",\"@action\"],[\"admin.events.subscription.deauthorize.title\",[30,0,[\"unauthorizing\"]],[30,0,[\"deauthorize\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"admin.events.subscription.deauthorize.label\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@label\",\"@title\",\"@action\",\"@disabled\"],[\"id-card\",\"admin.events.subscription.authorize.label\",\"admin.events.subscription.authorize.title\",[30,0,[\"authorize\"]],[30,0,[\"authorizeDisabled\"]]]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"conditional-loading-spinner\",\"d-button\",\"i18n\",\"events-subscription-badge\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-subscription-status.hbs",
    "isStrictMode": false
  });
  class EventsSubscriptionStatus extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "subscription", [(0, _service.service)("events-subscription")]))();
    #subscription = (() => (dt7948.i(this, "subscription"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "unauthorizing", [_tracking.tracked], function () {
      return false;
    }))();
    #unauthorizing = (() => (dt7948.i(this, "unauthorizing"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "authorizeDisabled", [(0, _computed.not)("subscription.supplierId")]))();
    #authorizeDisabled = (() => (dt7948.i(this, "authorizeDisabled"), void 0))();
    basePath = "/admin/plugins/subscription-client/suppliers";
    authorize() {
      window.location.href = `${this.basePath}/authorize?supplier_id=${this.subscription.supplierId}&final_landing_path=/admin/plugins/events`;
    }
    static #_5 = (() => dt7948.n(this.prototype, "authorize", [_object.action]))();
    deauthorize() {
      this.unauthorizing = true;
      (0, _ajax.ajax)(`${this.basePath}/authorize`, {
        type: "DELETE",
        data: {
          supplier_id: this.subscription.supplierId
        }
      }).then(result => {
        if (result.success) {
          this.subscription.supplierId = result.supplier_id;
          this.subscription.authorized = false;
        }
      }).finally(() => {
        this.unauthorizing = false;
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_6 = (() => dt7948.n(this.prototype, "deauthorize", [_object.action]))();
  }
  _exports.default = EventsSubscriptionStatus;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventsSubscriptionStatus);
});