define("discourse/plugins/discourse-events/discourse/services/events-subscription", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SUBSCRIBE_PATH = "/s";
  const SUPPORT_PATH = "/";
  const BASE_URL = "https://discourse.angus.blog";
  class EventsSubscriptionService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "subscribed", [_tracking.tracked], function () {
      return false;
    }))();
    #subscribed = (() => (dt7948.i(this, "subscribed"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "authorized", [_tracking.tracked], function () {
      return false;
    }))();
    #authorized = (() => (dt7948.i(this, "authorized"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "supplierId", [_tracking.tracked], function () {
      return null;
    }))();
    #supplierId = (() => (dt7948.i(this, "supplierId"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "product", [_tracking.tracked], function () {
      return "";
    }))();
    #product = (() => (dt7948.i(this, "product"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "features", [_tracking.tracked], function () {
      return {};
    }))();
    #features = (() => (dt7948.i(this, "features"), void 0))();
    async init() {
      super.init(...arguments);
      await this.getSubscriptionStatus();
    }
    async getSubscriptionStatus() {
      let update = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let url = "/admin/plugins/events/subscription";
      if (update) {
        url += "?update_from_remote=true";
      }
      let result = await (0, _ajax.ajax)(url).catch(_ajaxError.popupAjaxError);
      this.authorized = result.authorized;
      this.supplierId = result.supplier_id;
      this.subscribed = result.subscribed;
      this.product = result.product;
      this.features = result.features;
    }
    supportsFeatureValue(feature, attribute, value) {
      if (!this.subscribed || !value || !attribute) {
        return false;
      } else {
        const featureValues = this.features[feature][attribute][value];
        return featureValues && featureValues[this.product];
      }
    }
    get upgradePath() {
      return BASE_URL + SUBSCRIBE_PATH;
    }
    get ctaPath() {
      switch (this.product) {
        case "none":
          return BASE_URL + SUBSCRIBE_PATH;
        case "community":
          return BASE_URL + SUPPORT_PATH;
        case "business":
          return BASE_URL + SUPPORT_PATH;
        default:
          return BASE_URL + SUBSCRIBE_PATH;
      }
    }
  }
  _exports.default = EventsSubscriptionService;
});