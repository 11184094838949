define("discourse/plugins/discourse-events/discourse/components/custom-wizard-field-event", ["exports", "@ember/component", "@ember/object/computed", "@ember/template-factory"], function (_exports, _component, _computed, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EventForm
    @event={{@event}}
    @eventTimezones={{this.eventTimezones}}
    @updateEvent={{action "updateEvent"}}
  />
  */
  {
    "id": "RgxzN3G+",
    "block": "[[[8,[39,0],null,[[\"@event\",\"@eventTimezones\",\"@updateEvent\"],[[30,1],[30,0,[\"eventTimezones\"]],[28,[37,1],[[30,0],\"updateEvent\"],null]]],null]],[\"@event\"],false,[\"event-form\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/custom-wizard-field-event.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    layoutName: "javascripts/wizard/templates/components/wizard-field-event",
    eventTimezones: (0, _computed.alias)("field.event_timezones"),
    actions: {
      updateEvent(event, status) {
        this.set("field.value", event);
        this.field.setValid(status);
      }
    }
  }));
});