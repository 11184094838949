define("discourse/plugins/discourse-events/discourse/components/modal/events-confirm-event-deletion", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/models/event", "@ember/template-factory"], function (_exports, _component, _decorators, _I18n, _event, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="delete-event-modal"
    @title={{i18n "admin.events.event.delete.title"}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <div class="delete-events-count">
        {{i18n
          "admin.events.event.delete.description_x"
          count=this.model.eventIds.length
        }}
      </div>
      <div class="target-control">
        <ComboBox
          @content={{this.deleteTargets}}
          @value={{this.deleteTarget}}
          @onChange={{action (mut this.deleteTarget)}}
        />
      </div>
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{action "delete"}}
        @label={{this.btnLabel}}
        disabled={{this.destroying}}
      />
      <DButton
        class="btn-danger"
        @label="cancel"
        @action={{action "cancel"}}
        disabled={{this.destroying}}
      />
      <ConditionalLoadingSpinner @condition={{this.destroying}} @size="small" />
    </:footer>
  </DModal>
  */
  {
    "id": "4W8f8lFN",
    "block": "[[[8,[39,0],[[24,0,\"delete-event-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"admin.events.event.delete.title\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"delete-events-count\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"admin.events.event.delete.description_x\"],[[\"count\"],[[30,0,[\"model\",\"eventIds\",\"length\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"target-control\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"deleteTargets\"]],[30,0,[\"deleteTarget\"]],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"deleteTarget\"]]],null]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"],[16,\"disabled\",[30,0,[\"destroying\"]]]],[[\"@action\",\"@label\"],[[28,[37,3],[[30,0],\"delete\"],null],[30,0,[\"btnLabel\"]]]],null],[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-danger\"],[16,\"disabled\",[30,0,[\"destroying\"]]]],[[\"@label\",\"@action\"],[\"cancel\",[28,[37,3],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@condition\",\"@size\"],[[30,0,[\"destroying\"]],\"small\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"combo-box\",\"action\",\"mut\",\"d-button\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/events-confirm-event-deletion.hbs",
    "isStrictMode": false
  });
  const DELETE_TARGETS = ["events_only", "events_and_topics", "topics_only"];
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    deleteTargets: DELETE_TARGETS.map(t => ({
      id: t,
      name: _I18n.default.t(`admin.events.event.delete.${t}`)
    })),
    deleteTarget: "events_only",
    btnLabel(deleteTarget) {
      return `admin.events.event.delete.${deleteTarget}_btn`;
    },
    actions: {
      delete() {
        const eventIds = this.model.eventIds;
        const target = this.deleteTarget;
        const opts = {
          event_ids: eventIds,
          target
        };
        this.set("destroying", true);
        _event.default.destroy(opts).then(result => {
          if (result.success) {
            this.model.onDestroyEvents(eventIds.filter(eventId => result.destroyed_event_ids.includes(eventId)), eventIds.filter(eventId => result.destroyed_topics_event_ids.includes(eventId)));
            this.closeModal();
          } else {
            this.set("model.error", result.error);
          }
        }).finally(() => this.set("destroying", false));
      },
      cancel() {
        this.closeModal();
      }
    }
  }, [["method", "btnLabel", [(0, _decorators.default)("deleteTarget")]]])));
});