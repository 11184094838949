define("discourse/plugins/discourse-events/discourse/templates/connectors/discourse-post-event-status-going-button/post-event-video-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.event.customFields.video_url}}
    <EventVideoBtn @videoUrl={{@outletArgs.event.customFields.video_url}} />
  {{/if}}
  {{yield}}
  */
  {
    "id": "6ifnnvwQ",
    "block": "[[[41,[30,1,[\"event\",\"customFields\",\"video_url\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@videoUrl\"],[[30,1,[\"event\",\"customFields\",\"video_url\"]]]],null],[1,\"\\n\"]],[]],null],[18,2,null]],[\"@outletArgs\",\"&default\"],false,[\"if\",\"event-video-btn\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/discourse-post-event-status-going-button/post-event-video-button.hbs",
    "isStrictMode": false
  });
});