define("discourse/plugins/discourse-events/discourse/templates/admin-plugins-events", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminNav>
    <NavItem
      @route="adminPlugins.events.event"
      @label="admin.events.event.title"
    />
    <NavItem
      @route="adminPlugins.events.source"
      @label="admin.events.source.title"
    />
    <NavItem
      @route="adminPlugins.events.provider"
      @label="admin.events.provider.title"
    />
    <NavItem @route="adminPlugins.events.log" @label="admin.events.log.title" />
    <li class="settings">
      <DButton
        class="btn-default"
        @action={{route-action "showSettings"}}
        @actionParam={{this.plugin}}
        @icon="cog"
        @label="admin.plugins.change_settings_short"
      />
    </li>
    <div class="admin-actions">
      <EventsSubscriptionStatus />
    </div>
  </AdminNav>
  
  <div class="admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "Aq+vNT+D",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminPlugins.events.event\",\"admin.events.event.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminPlugins.events.source\",\"admin.events.source.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminPlugins.events.provider\",\"admin.events.provider.title\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\",\"@label\"],[\"adminPlugins.events.log\",\"admin.events.log.title\"]],null],[1,\"\\n  \"],[10,\"li\"],[14,0,\"settings\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-default\"]],[[\"@action\",\"@actionParam\",\"@icon\",\"@label\"],[[28,[37,3],[\"showSettings\"],null],[30,0,[\"plugin\"]],\"cog\",\"admin.plugins.change_settings_short\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"admin-actions\"],[12],[1,\"\\n    \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"admin-nav\",\"nav-item\",\"d-button\",\"route-action\",\"events-subscription-status\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/admin-plugins-events.hbs",
    "isStrictMode": false
  });
});