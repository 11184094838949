define("discourse/plugins/discourse-events/discourse/components/events-subscription-selector", ["exports", "@ember/service", "discourse-common/utils/decorators", "I18n", "select-kit/components/single-select"], function (_exports, _service, _decorators, _I18n, _singleSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _singleSelect.default.extend(dt7948.p({
    classNames: ["combo-box", "events-subscription-selector"],
    subscription: (0, _service.service)("events-subscription"),
    selectKitOptions: {
      autoFilterable: false,
      filterable: false,
      showFullTitle: true,
      headerComponent: "events-subscription-selector/events-subscription-selector-header",
      caretUpIcon: "caret-up",
      caretDownIcon: "caret-down"
    },
    content(feature, attribute, subscriptionFeatures, allowedValues) {
      const attributes = (subscriptionFeatures || {})[feature];
      if (!attributes) {
        return [];
      }
      const values = attributes[attribute];
      if (!values) {
        return [];
      } else {
        return Object.keys(values).filter(value => allowedValues ? allowedValues.includes(value) : true).map(value => {
          let minimumProduct = Object.keys(values[value]).find(product => values[value][product]);
          let subscriptionRequired = minimumProduct !== "none";
          let i18nkey = `admin.events.${feature}.${attribute}.${value}`;
          if (this.i18nSuffix) {
            i18nkey += `.${this.i18nSuffix}`;
          }
          let attrs = {
            id: value,
            name: _I18n.default.t(i18nkey),
            subscriptionRequired,
            minimumProduct
          };
          if (subscriptionRequired) {
            attrs.subscribed = this.subscription.supportsFeatureValue(feature, attribute, value);
            attrs.disabled = !attrs.subscribed;
            attrs.selectorLabel = `admin.events.subscription.${attrs.subscribed ? "subscribed" : "not_subscribed"}.selector`;
          }
          return attrs;
        });
      }
    },
    modifyComponentForRow() {
      return "events-subscription-selector/events-subscription-selector-row";
    }
  }, [["method", "content", [(0, _decorators.default)("feature", "attribute", "subscription.features", "allowedValues")]]]));
});