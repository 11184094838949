define("discourse/plugins/discourse-events/discourse/components/events-event-table", ["exports", "@ember/component", "discourse/mixins/load-more", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/models/event", "@ember/template-factory"], function (_exports, _component, _loadMore, _decorators, _event, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class="grid">
    <thead>
      <tr>
        <TableHeaderToggle
          @tagName="th"
          @field="start_time"
          @labelKey="admin.events.event.start_time"
          @order={{this.order}}
          @asc={{this.asc}}
          @automatic={{true}}
          class="start-time"
        />
        <TableHeaderToggle
          @tagName="th"
          @field="name"
          @labelKey="admin.events.event.name"
          @order={{this.order}}
          @asc={{this.asc}}
          @automatic={{true}}
        />
        <th></th>
        <th class="event-actions">
          <DButton
            @action={{action "toggleSelectAll"}}
            @title="admin.events.event.select_all"
          >
            <span class="events-selected">
              {{#if this.selectAll}}
                {{i18n
                  "admin.events.event.selected_x"
                  count=this.selectedEventIds.length
                }}
              {{else}}
                {{i18n "admin.events.event.select_all"}}
              {{/if}}
            </span>
            {{d-icon "square-check"}}
          </DButton>
        </th>
      </tr>
    </thead>
    <tbody>
      {{#each this.events as |event|}}
        <EventsEventRow
          @event={{event}}
          @providers={{this.providers}}
          @modifySelection={{this.modifySelection}}
          @showTopics={{this.showTopics}}
        />
      {{/each}}
    </tbody>
  </table>
  <ConditionalLoadingSpinner @condition={{this.loading}} />
  */
  {
    "id": "2+q/gvvw",
    "block": "[[[10,\"table\"],[14,0,\"grid\"],[12],[1,\"\\n  \"],[10,\"thead\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"start-time\"]],[[\"@tagName\",\"@field\",\"@labelKey\",\"@order\",\"@asc\",\"@automatic\"],[\"th\",\"start_time\",\"admin.events.event.start_time\",[30,0,[\"order\"]],[30,0,[\"asc\"]],true]],null],[1,\"\\n      \"],[8,[39,0],null,[[\"@tagName\",\"@field\",\"@labelKey\",\"@order\",\"@asc\",\"@automatic\"],[\"th\",\"name\",\"admin.events.event.name\",[30,0,[\"order\"]],[30,0,[\"asc\"]],true]],null],[1,\"\\n      \"],[10,\"th\"],[12],[13],[1,\"\\n      \"],[10,\"th\"],[14,0,\"event-actions\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@action\",\"@title\"],[[28,[37,2],[[30,0],\"toggleSelectAll\"],null],\"admin.events.event.select_all\"]],[[\"default\"],[[[[1,\"\\n          \"],[10,1],[14,0,\"events-selected\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectAll\"]],[[[1,\"              \"],[1,[28,[35,4],[\"admin.events.event.selected_x\"],[[\"count\"],[[30,0,[\"selectedEventIds\",\"length\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[35,4],[\"admin.events.event.select_all\"],null]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n          \"],[1,[28,[35,5],[\"square-check\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"events\"]]],null]],null],null,[[[1,\"      \"],[8,[39,8],null,[[\"@event\",\"@providers\",\"@modifySelection\",\"@showTopics\"],[[30,1],[30,0,[\"providers\"]],[30,0,[\"modifySelection\"]],[30,0,[\"showTopics\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,9],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null]],[\"event\"],false,[\"table-header-toggle\",\"d-button\",\"action\",\"if\",\"i18n\",\"d-icon\",\"each\",\"-track-array\",\"events-event-row\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/events-event-table.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(_loadMore.default, dt7948.p({
    classNames: ["events-event-table"],
    eyelineSelector: ".events-event-row",
    loadingComplete: false,
    showTopics(filter) {
      return filter === "connected";
    },
    selectAllEvents() {
      _event.default.listAll({
        filter: this.filter
      }).then(result => {
        this.modifySelection(result.event_ids, true);
      });
    },
    actions: {
      toggleSelectAll() {
        this.toggleProperty("selectAll");
        if (this.selectAll) {
          this.selectAllEvents();
        } else {
          this.modifySelection(this.selectedEventIds, false);
        }
      },
      loadMore() {
        if (this.loading || this.loadingComplete) {
          return;
        }
        const page = this.page + 1;
        let params = {
          page
        };
        if (this.filter) {
          params.filter = this.filter;
        }
        if (this.asc) {
          params.asc = this.asc;
        }
        if (this.order) {
          params.order = this.order;
        }
        this.set("loading", true);
        _event.default.list(params).then(result => {
          if (result.events && result.events.length) {
            this.set("page", page);
            this.get("events").pushObjects(_event.default.toArray(result.events, this.selectedEventIds));
          } else {
            this.set("loadingComplete", true);
          }
        }).finally(() => this.set("loading", false));
      }
    }
  }, [["method", "showTopics", [(0, _decorators.default)("filter")]]])));
});